import React, { useState, useEffect } from 'react';

interface DatePickerProps {
  onDateChange: (date: string) => void;
  initialYear?: number;
  initialMonth?: number;
  initialDay?: number;
  earliestYear?: number;
}

const DatePicker: React.FC<DatePickerProps> = ({
  onDateChange,
  initialYear = new Date().getFullYear(),
  initialMonth = new Date().getMonth() + 1, // JavaScript months are 0-11
  initialDay = new Date().getDate(),
  earliestYear = 1900
}) => {
  const [year, setYear] = useState<number>(initialYear);
  const [month, setMonth] = useState<number>(initialMonth);
  const [day, setDay] = useState<number>(initialDay);

  useEffect(() => {
    handleDateChange();
  }, [year, month, day]);

  const handleDateChange = () => {
    const formattedDate = `${year}${String(month).padStart(2, '0')}${String(day).padStart(2, '0')}0000`;
    onDateChange(formattedDate);
  };

  const handleYearChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setYear(Number(e.target.value));
  };

  const handleMonthChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setMonth(Number(e.target.value));
  };

  const handleDayChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setDay(Number(e.target.value));
  };

  const generateYears = () => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let i = currentYear; i >= earliestYear; i--) {
      years.push(i);
    }
    return years;
  };

  const generateMonths = () => {
    return Array.from({ length: 12 }, (_, i) => i + 1);
  };

  const generateDays = () => {
    return Array.from({ length: 31 }, (_, i) => i + 1);
  };

  return (
    <div>
      <select value={year} onChange={handleYearChange}>
        {generateYears().map(year => (
          <option key={year} value={year}>{year}</option>
        ))}
      </select>
      <select value={month} onChange={handleMonthChange}>
        {generateMonths().map(month => (
          <option key={month} value={month}>{month}</option>
        ))}
      </select>
      <select value={day} onChange={handleDayChange}>
        {generateDays().map(day => (
          <option key={day} value={day}>{day}</option>
        ))}
      </select>
    </div>
  );
};

export default DatePicker;
