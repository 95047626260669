import React, { useState, useEffect } from 'react';


const ViewOrdersComponent: React.FC = () => {

  return(
    <div>
      My Stuff
    </div>
  )
};

export default ViewOrdersComponent;