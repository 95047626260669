// src/App.tsx
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
//import HomePage from './portalcomponent/HomePage';
//import EHSFulfillmentComponent from './portalcomponent/EHSFulfillmentComponent';
import LoginComponent from './logincomponent/LoginComponent';
import DashboardComponent from './portalcomponent/views/DashboardComponent';
import ViewOrdersComponent from './portalcomponent/views/ViewOrdersComponent';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/fulfillmentportal" element={
          <div className="App">
            <header className="App-header">
              <h1>Portal Login</h1>
              <LoginComponent/>
            </header>
          </div>
        } />

        <Route path="/fulfillmentdashboard" element={
          <div className="DashboardComponent">
            <header className="App-header">
              <h1>Dashboard</h1>
              <DashboardComponent/>
            </header>
          </div>
        } />

        <Route path="/vieworders" element={
          <div className="ViewOrdersComponent">
            <header className="App-header">
              <h1>View Orders</h1>
              <ViewOrdersComponent/>
            </header>
          </div>
        } />

        <Route path="/" element={
          <div className="App">
            <p>This .net page is not active. Please visit:</p>
            <a href="https://ownyourlabs.com">OwnYourLabs.com</a>
          </div>
        } />
      </Routes>
    </Router>
  );

  // return (
  //   <div className="App">
  //     <header className="App-header">
  //       <HomePage />
  //     </header>
  //   </div>
  // );
  // return (
  //   <div className="App">
  //     <p>This .net page is not active. Please visit:</p>
  //     <a href="https://ownyourlabs.com">OwnYourLabs.com</a>
  //   </div>
  // );
  // return (
  //   <div className="App">
  //     <header className="App-header">
  //       <h1>OYL Fulfillment</h1>
  //       <EHSFulfillmentComponent/>
  //     </header>
  //   </div>
  // );
  // return (
  //   <div className="App">
  //     <header className="App-header">
  //       <h1>Portal Login</h1>
  //       <LoginComponent/>
  //     </header>
  //   </div>
  // );
  
}

export default App;
