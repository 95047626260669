import React, { useState, useEffect } from 'react';
import './LoginComponent.css';
import { useNavigate } from 'react-router-dom';

const USER_ROLE_DEV_ADMIN = 1;
const USER_ROLE_DEV = 2;
const USER_ROLE_FT_ADMIN = 3;
const USER_ROLE_FT = 4;

const LoginComponent: React.FC = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  
  // Automatically log out the user when the component mounts
  useEffect(() => {
    const handleLogout = async () => {
      try {
        const response = await fetch('/logout', {
          method: 'POST',
          credentials: 'include', // Include cookies in the request
        });

        if (response.ok) {
          console.log('Logged out successfully');
        } else {
          console.error('Logout failed');
        }
      } catch (error) {
        console.error('Error during logout:', error);
      }
    };

    handleLogout(); // Call the logout function when the component mounts
  }, []); // Empty dependency array ensures this runs once when the component mounts

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await fetch('/login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password })
      });

      if (response.ok) {
        const data = await response.json();
        setMessage('Login successful');
        // Fetch session data to check the user's role
        const sessionResponse = await fetch('/api/session');
        const sessionData = await sessionResponse.json();
        if (sessionData.loggedIn) {
          // if (sessionData.role === USER_ROLE_DEV_ADMIN || sessionData.role === USER_ROLE_DEV) {
          //   // optional -- open dev view
            
          // } else if (sessionData.role === USER_ROLE_FT_ADMIN || sessionData.role === USER_ROLE_FT) {
          //   // optional -- open fulfillment team view
          // } else {
          //   console.error('Unknown role:', sessionData.role);
          // }
          navigate('/fulfillmentdashboard');
        }
      } else {
        const errorData = await response.json();
        setMessage(errorData.error);
      }
    } catch (error) {
      setMessage('An error occurred. Please try again.');
    }
  };

  return (
    <div className="login-container">
      <h2>Login</h2>
      <form onSubmit={handleLogin}>
        <div className="form-group">
          <label htmlFor="username">Username</label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <button type="submit">SUBMIT</button>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
};

export default LoginComponent;
